import * as React from "react";
import { css } from "@emotion/react";

// 色やサイズは上位の要素に対して設定する
interface IProps {
  children?: React.ReactNode;
  styleKey: keyof IIconFontStyle;
}

export const IconComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { children, styleKey } = props;
  const iconFontStyles: any = iconFontStyle[styleKey];
  return <i css={iconFontStyles}>{children}</i>;
};
export const Icon: any = React.memo(IconComponent);

const iconFontBaseStyle = css`
  overflow: hidden;
  margin: 0;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const iconStarFull = "\\e900";
// const iconStarHalf = "\\e901";
const iconArrowThinL = "\\e902";
const iconArrowThinR = "\\e903";
const iconArrowDown = "\\e904";
const iconArrowUp = "\\e905";
const iconArrowRight = "\\e906";
const iconArrowLeft = "\\e907";
const iconStarLine = "\\e908";
const iconPin = "\\e909";
const iconClose = "\\e90a";
const iconLine = "\\e90b";
const iconFacebook = "\\e90c";
const iconAirplane = "\\e90d";
const iconHotel = "\\e90e";
const iconRestaurant = "\\e90f";
const iconTravelbag = "\\e910";
const iconLoopSwitch = "\\ea2e";
const iconSearch = "\\e911";
const iconCity = "\\e912";
const iconCaution = "\\e913";
const iconInfo = "\\e914";
const iconImages = "\\e915";
const iconCheck = "\\e916";

export interface IIconFontStyle {
  star: any;
  arrowThinL: any;
  arrowThinR: any;
  arrowDown: any;
  arrowUp: any;
  arrowRight: any;
  arrowLeft: any;
  starLine: any;
  pin: any;
  close: any;
  line: any;
  facebook: any;
  airplane: any;
  hotel: any;
  restaurant: any;
  travelbag: any;
  loopSwitch: any;
  search: any;
  city: any;
  caution: any;
  info: any;
  images: any;
  check: any;
}

const iconFontStyle: IIconFontStyle = {
  star: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconStarFull}";
    }
  `,
  arrowThinL: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconArrowThinL}";
    }
  `,
  arrowThinR: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconArrowThinR}";
    }
  `,
  arrowDown: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconArrowDown}";
    }
  `,
  arrowUp: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconArrowUp}";
    }
  `,
  arrowRight: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconArrowRight}";
    }
  `,
  arrowLeft: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconArrowLeft}";
    }
  `,
  starLine: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconStarLine}";
    }
  `,
  pin: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconPin}";
    }
  `,
  close: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconClose}";
    }
  `,
  line: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconLine}";
    }
  `,
  facebook: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconFacebook}";
    }
  `,
  airplane: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconAirplane}";
    }
  `,
  hotel: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconHotel}";
    }
  `,
  restaurant: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconRestaurant}";
    }
  `,
  travelbag: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconTravelbag}";
    }
  `,
  loopSwitch: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconLoopSwitch}";
    }
  `,
  search: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconSearch}";
    }
  `,
  city: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconCity}";
    }
  `,
  caution: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconCaution}";
    }
  `,
  info: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconInfo}";
    }
  `,
  images: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconImages}";
    }
  `,
  check: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconCheck}";
    }
  `,
};
